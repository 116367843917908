import { VIEW_PERMISSION, DUPLICATE_PERMISSION, ADD_POT_PERMISSION, PROMOTIONAL_PLANNING_PROMO_OPTIMIZATION_DETAIL, PROMOTIONAL_PLANNING_PROMO_ENTRY } from "@kraftheinz/common";
import { n as normalizeComponent, f as PROMO_SALES_PLAN, e as PROMO_SALES_ACCEPTED, P as PROMO_SALES_REVIEW, j as PROMO_STATUS_COLORS, k as POT_STATUSES } from "./index.js";
import { a as PROMO_DATE_FORMAT_FOR_SALE } from "./others.js";
import { P as PromoUtils } from "./promotion.planning.js";
import "numeral";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("list-composer", { attrs: { "actions-width": 97, "show-clear-filters-button": false, "default-query-strings": _vm.defaultQueryStrings, "fetch-on-first-mount": false, "has-active": false, "has-filter-inactive": false, "ph-search-by": "Search by PPG", "search-by": "Name", "scroll": { x: 1600 }, "title": "PromoDB Home Page" }, scopedSlots: _vm._u([{ key: "customFilter", fn: function() {
    return [_c("a-row", { staticClass: "mr-0 w-100", attrs: { "type": "flex", "gutter": [8, 8] } }, [_c("a-col", { attrs: { "span": 6 } }, [_c("select-input", { attrs: { "allow-clear": "", "clear-data-on-destroy": false, "clear-value-on-options-change": false, "disabled": _vm.isFetchingList, "placeholder": "Select Region", "reference": "promotion-planning.common.regions", "source": "code", "source-label": "description", "source-description": "description", "value": _vm.regionFilter.value }, on: { "change": function($event) {
      return _vm.onFilter(_vm.filterKeys.region, $event);
    } } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("select-input", { attrs: { "allow-clear": "", "clear-data-on-destroy": false, "clear-value-on-options-change": false, "disabled": _vm.isFetchingList, "mode": "multiple", "max-tag-count": 1, "placeholder": "Select Key Account", "reference": "promotion-planning.common.customers", "source": "id", "source-label": function(opt) {
      return _vm.generateLabel(opt, ["keyAccountCode", "keyAccount"]);
    }, "source-description": "keyAccount", "value": _vm.customerFilter.value }, on: { "change": function($event) {
      return _vm.onFilter(_vm.filterKeys.customer, $event);
    } } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("select-input", { attrs: { "allow-clear": "", "clear-data-on-destroy": false, "clear-value-on-options-change": false, "disabled": _vm.isFetchingList, "placeholder": "Select Cluster", "queries": _vm.filterQueries, "reference": "promotion-planning.common.clusters", "source": "code", "source-label": "description", "source-description": "description", "value": _vm.clusterFilter.value }, on: { "change": function($event) {
      return _vm.onFilter(_vm.filterKeys.cluster, $event);
    } } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("a-button", { attrs: { "type": "primary", "disabled": _vm.isFetchingList }, on: { "click": _vm.fetchList } }, [_vm._v(" Apply Filters ")])], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("select-input", { attrs: { "allow-clear": "", "clear-data-on-destroy": false, "clear-value-on-options-change": false, "disabled": _vm.isFetchingList, "option-filter": function(subCategory) {
      return subCategory.code !== "-1";
    }, "placeholder": "Select Sub Category", "reference": "promotion-planning.common.sub-categories", "source": "code", "source-label": function(opt) {
      return _vm.generateLabel(opt, ["code", "description"]);
    }, "source-description": "description", "value": _vm.subCategoriesFilter.value }, on: { "change": function($event) {
      return _vm.onFilter(_vm.filterKeys.subCategory, $event);
    } } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("select-input", { attrs: { "allow-clear": "", "clear-data-on-destroy": false, "clear-value-on-options-change": false, "reference": "promotion-planning.common.plans", "disabled": _vm.isFetchingList, "source": "name", "source-label": "name", "source-description": "name", "value": _vm.scenarioFilter.value, "placeholder": "Select Scenario" }, on: { "change": function($event) {
      return _vm.onFilter(_vm.filterKeys.scenario, $event);
    } } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("select-input", { attrs: { "clear-data-on-destroy": false, "clear-value-on-options-change": false, "default-value": _vm.defaultYear, "reference": "promotion-planning.common.year", "disabled": _vm.isFetchingList, "source": "year", "source-label": "year", "source-description": "year", "value": _vm.yearFilter.value, "placeholder": "Select Year" }, on: { "change": function($event) {
      return _vm.onFilter(_vm.filterKeys.year, $event);
    } } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("a-button", { attrs: { "disabled": _vm.isFetchingList, "icon": "close-circle" }, on: { "click": _vm.onClearFiltersClick } }, [_vm._v(" Clear Filters ")])], 1)], 1)];
  }, proxy: true }, { key: "create-buttons", fn: function(ref) {
    var onClick = ref.onClick;
    return [_c("a-space", { attrs: { "size": 8 } }, [_c("a-button", { attrs: { "type": "primary" }, on: { "click": _vm.openTableau } }, [_vm._v(" Tableau Dashboard ")]), _vm.canAdd ? _c("a-button", { attrs: { "type": "primary" }, on: { "click": onClick } }, [_vm._v(" Add POT ")]) : _vm._e()], 1)];
  } }, { key: "action", fn: function(ref) {
    var record = ref.record;
    return [_c("div", { staticClass: "d-flex justify-content-center" }, [_c("div", { staticClass: "action__icon-container" }, [_c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("POT")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "dollar", "type": "link", "disabled": !record.isPOTEnabled }, on: { "click": function($event) {
      return _vm.goToPOTDetail(record);
    } } })], 1)], 1), _c("div", { staticClass: "action__icon-container" }, [_c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Promo Details")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "calendar", "type": "link", "disabled": !record.isPromoDetailEnabled }, on: { "click": function($event) {
      return _vm.goToEntryDetail(record);
    } } })], 1)], 1)])];
  } }]) }, [_c("text-field", { key: "RegionDescription", attrs: { "data-index": "regionDescription", "title": "Region", "width": 100, "sorter": true } }), _c("text-field", { key: "StateDescription", attrs: { "data-index": "stateDescription", "title": "State", "width": 150, "sorter": true } }), _c("text-field", { key: "KeyAccount", attrs: { "data-index": "keyAccount", "title": "Key Account", "width": 150, "sorter": true } }), _c("text-field", { key: "SubCategoryDescription", attrs: { "data-index": "subCategoryDescription", "title": "Subcategory", "width": 200, "sorter": true } }), _c("url-field", { key: "Name", attrs: { "class-name": "word-break-all", "base-url": _vm.getBaseUrlOfDetailPage, "data-index": "name", "title": "PPG", "sorter": true, "width": 350, "clickable": _vm.hasViewPermission } }), _c("tag-field", { key: "Status", attrs: { "data-index": "status", "title": "Status", "width": 150, "sorter": true, "color": _vm.getStatusColor } }), _c("freq-check-icon-field", { key: "NewFreqCheck", attrs: { "data-index": "newFreqCheck", "title": "Freq Check", "sorter": true, "width": 150, "align": "center" } }), _c("tooltip-field", { key: "Notes", attrs: { "data-index": "notes", "title": "Notes", "parse": _vm.parseComment, "width": 250 } }), _c("text-field", { key: "EffectiveFromDate", attrs: { "allow-html": "", "data-index": "effectiveWeeks", "title": "Effective Weeks", "parse": _vm.parseEffectiveWeeks, "width": 200, "sorter": true } }), _c("text-field", { key: "LastUpdated", attrs: { "data-index": "lastUpdated", "title": "Last Updated", "parse": _vm.parseLastUpdated, "width": 200, "sorter": true } })], 1)], 1);
};
var staticRenderFns$1 = [];
var ListLandingPage_vue_vue_type_style_index_0_lang = "";
const __vue2_script$1 = {
  name: "ListPromo",
  mixins: [PromoUtils],
  inject: ["can", "crud", "resourceProps", "resourceName"],
  data() {
    const [, , , subCategoryProps, , yearProps] = this.resourceProps;
    return {
      filterKeys: {
        cluster: "Cluster",
        customer: "CustomerId",
        region: "Region",
        scenario: "scenarioName",
        subCategory: "SubCategory",
        year: "year"
      },
      isLoading: true,
      permissions: {
        view: VIEW_PERMISSION,
        duplicate: DUPLICATE_PERMISSION
      },
      proEntryStatus: {
        salePlan: PROMO_SALES_PLAN,
        saleAccepted: PROMO_SALES_ACCEPTED,
        saleReview: PROMO_SALES_REVIEW
      },
      promoIncludedNotes: ["salereview", "revmanreview"],
      subCategoryProps,
      subCategories: [],
      tableauUrl: "https://analytics.kraftheinz.com/#/site/CrossFunctionalAnalytics/views/PromoExecutionandCompliance/LandingPage?:origin=card_share_link&:embed=n",
      yearProps
    };
  },
  computed: {
    canAdd() {
      return this.$can(ADD_POT_PERMISSION, PROMOTIONAL_PLANNING_PROMO_OPTIMIZATION_DETAIL);
    },
    hasViewPermission() {
      return this.can(this.permissions.view);
    },
    regionFilter() {
      return this.crud.getFilter(this.filterKeys.region) || "";
    },
    clusterFilter() {
      return this.crud.getFilter(this.filterKeys.cluster) || "";
    },
    customerFilter() {
      return this.crud.getFilter(this.filterKeys.customer) || "";
    },
    subCategoriesFilter() {
      return this.crud.getFilter(this.filterKeys.subCategory) || "";
    },
    scenarioFilter() {
      return this.crud.getQueryString(this.filterKeys.scenario) || "";
    },
    yearFilter() {
      return this.crud.getQueryString(this.filterKeys.year) || "";
    },
    filterQueries() {
      return [["All", false]];
    },
    commonYears() {
      return this.yearProps.crud.getList();
    },
    defaultYear() {
      const finYear = this.commonYears.find((year) => year.isDefault);
      return finYear ? finYear.year : null;
    },
    defaultQueryStrings() {
      return [{ key: this.filterKeys.year, value: this.defaultYear }];
    },
    isFetchingList() {
      return this.$store.state[this.resourceName].isFetchingList || this.isLoading;
    }
  },
  watch: {
    defaultYear(newVal) {
      this.onFilter(this.filterKeys.year, newVal);
      this.fetchList();
    }
  },
  created() {
    this.crud.setPagination({ page: 1, pageSize: 40 });
    this.subCategoryProps.crud.deleteFilter("IsInactive");
    this.fetchList();
  },
  beforeUpdate() {
    this.isLoading = false;
  },
  methods: {
    fetchList() {
      this.crud.fetchList();
    },
    async onClearFiltersClick() {
      this.crud.clearFilters();
      this.crud.deleteQueryStrings();
      this.crud.deleteFilter("IsInactive");
      this.onFilter(this.filterKeys.year, this.$moment().year());
      this.fetchList();
    },
    getStatusColor(value, record) {
      return PROMO_STATUS_COLORS[record.statusCode];
    },
    parseDate(date) {
      return this.$moment(date).format(PROMO_DATE_FORMAT_FOR_SALE);
    },
    parseLastUpdated(value, record) {
      return `${this.parseDate(value)} by ${record.fullName}`;
    },
    parseEffectiveWeeks(value) {
      return value.map((week) => `${this.parseDate(week.fromDate)} - ${this.parseDate(week.toDate)} ${week.isOnline ? "(O)" : ""}`).join("<br />");
    },
    getBaseUrlOfDetailPage(record) {
      return POT_STATUSES.includes(record.statusCode) ? `/promotion-planning/promo-optimization/${record.promotionOptimisationId}` : `/promotion-planning/promo-entries/${record.promotionEntryId}`;
    },
    goToPOTDetail(record) {
      if (!record)
        return;
      this.$router.push({
        name: "POEdit",
        params: { id: record.promotionOptimisationId },
        query: {
          status: record.statusCode
        }
      });
    },
    goToEntryDetail(record) {
      if (!record)
        return;
      this.$router.push({
        name: "PromoEdit",
        params: {
          id: record.promotionEntryId
        }
      });
    },
    getFilterOperator(key) {
      switch (key) {
        case this.filterKeys.customer:
          return "In";
        default:
          return "Eq";
      }
    },
    async onFilter(key, value) {
      if (!value || Array.isArray(value) && !value.length) {
        switch (key) {
          case this.filterKeys.scenario:
          case this.filterKeys.year:
            this.crud.deleteQueryString(key);
            break;
          default:
            this.crud.deleteFilter(key);
            break;
        }
      } else {
        switch (key) {
          case this.filterKeys.scenario:
          case this.filterKeys.year:
            this.crud.setQueryString(key, value);
            break;
          default:
            this.crud.setFilter(key, {
              operator: this.getFilterOperator(key),
              value
            });
            break;
        }
      }
    },
    hasComment(status) {
      return this.promoIncludedNotes.includes(status);
    },
    openTableau() {
      window.open(this.tableauUrl, "_blank");
    },
    parseComment(value, record) {
      const { listComment, statusCode } = record;
      return !this.hasComment(statusCode) || !listComment || !listComment.length ? "" : listComment.join("\n");
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListLandingPage = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-promo" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": [
    "promotion-planning.common.regions",
    "promotion-planning.common.customers",
    "promotion-planning.common.clusters",
    "promotion-planning.common.sub-categories",
    "promotion-planning.common.plans",
    "promotion-planning.common.year"
  ], "resource-id-name": ["code", "id", "code", "code", "name", "year"] } }, [_c("resource", { attrs: { "api-url": _vm.apiUrl, "create-route": "/promotion-planning/promo-optimization/create", "filter-route": "/promotion-planning/landing-page/advanced-search", "name": "promotion-planning.promo", "page": _vm.page } }, [_c("list-landing-page")], 1)], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListLandingPage
  },
  data() {
    return {
      apiUrl,
      page: PROMOTIONAL_PLANNING_PROMO_ENTRY,
      ListLandingPage,
      itemsMenu: [
        {
          key: "landing-page",
          title: "PromoDB Home Page",
          path: ""
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
